import axios, { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/browser';
import { responseType } from '../../utils/constants';
import { getB2BMembersUrl, getB2BOrgSlugUrl } from '../../utils/urls';
import { createAuthorizationHeader } from '../../utils/auth';
import {
  CreateMemberByEmail,
  MembersSearchResult,
  MembersSearchQuery,
  CreateMember,
  MemberStatus,
  MembersSearchActiveQuery,
} from './members.types';

export const createMemberByEmail: CreateMemberByEmail = ({ slug, email }) =>
  axios.post<CreateMember>(getB2BOrgSlugUrl(slug).CREATE_MEMBER_BY_EMAIL, {
    email,
  });

export const getExportMembers = async (
  orgId: number,
  params: URLSearchParams,
): Promise<AxiosResponse<OrgMembersCSVResponse>> => {
  try {
    const response = await axios.request({
      headers: createAuthorizationHeader(),
      method: 'get',
      params,
      responseType: responseType.BLOB,
      url: getB2BMembersUrl(orgId).EXPORT_CSV,
    });

    return response;
  } catch (e) {
    Sentry.addBreadcrumb({
      message: `Failed in exportMembers with Error:${e}`,
    });
    throw e;
  }
};

export const searchMembers = async (
  query: MembersSearchQuery,
): Promise<MembersSearchResult> => {
  const { orgId, ...searchQuery } = query;
  const statusArray = searchQuery.status?.map((status) => ['status', status]);
  const searchQueryArray = Object.entries(searchQuery)
    .filter(([k, v]) => k !== 'status')
    .map(([k, v]) => [k, String(v)])
    .concat(statusArray || []);

  try {
    const response = await axios.get<MembersSearchResult>(
      getB2BMembersUrl(orgId).DB_SEARCH,
      {
        headers: createAuthorizationHeader(),
        params: new URLSearchParams(searchQueryArray),
        responseType: responseType.JSON,
      },
    );

    return response.data;
  } catch (e) {
    Sentry.addBreadcrumb({
      message: `Failed in searchMembers with Error:${e}`,
    });
    throw e;
  }
};

export const getTotalActiveMembers = async (orgId: number): Promise<number> => {
  const searchQuery: MembersSearchActiveQuery = {
    limit: 1,
    offset: 1,
    bypassStatusFilter: false,
    status: MemberStatus.ACTIVE,
  };
  try {
    const response = await axios.get<MembersSearchResult>(
      getB2BMembersUrl(orgId).DB_SEARCH,
      {
        headers: createAuthorizationHeader(),
        params: searchQuery,
        responseType: responseType.JSON,
      },
    );

    return response.data.total;
  } catch (e) {
    Sentry.addBreadcrumb({
      message: `Failed in getTotalActiveMembers with Error:${e}`,
    });
    throw e;
  }
};
