import { put, takeLatest } from 'redux-saga/effects';
import { requestDeauthenticate, removeCookie } from '@headspace/web-auth';
import { MemberEnrollActionTypes } from '../../actions/memberEnrollActions/memberEnrollActionTypes';
import { HeadspaceHubMemberDetailsActionTypes } from '../../../../headspaceHub/state/actions/headspaceHubMemberDetailsActions/actionTypes';
import { logSentryRequestError } from '../../../../../utils/sentryHelpers';
import { ACCESS_GROUPS } from '../../../../headspaceHub/constants/accessGroups';
import { headspaceHubRoutes } from '../../../../headspaceHub/constants/routes';
import { getConfig } from '../../../../../config';
import { router } from '../../../../../root-component/router';

const { AUTH_TOKEN_COOKIE_NAME } = getConfig();

export function* waitForAuthSaga() {
  yield takeLatest(
    MemberEnrollActionTypes.FETCH_USER_PROFILE,
    handleFetchUserProfile,
  );
}

type Params = { type: string; error: any; sequence: string; payload: any };
function* handleFetchUserProfile({ payload, error, sequence }: Params) {
  const privileges = payload?.privileges || [];
  const isHealthHubRoute =
    window.location.pathname.match(/^\/hub(?:\/(.*))?$/i) !== null;

  if (isHealthHubRoute && sequence === 'done') {
    if (error) {
      yield put({
        type:
          HeadspaceHubMemberDetailsActionTypes.CLINICAL_COVERED_USER_LOG_IN_FAILED,
      });

      yield put(requestDeauthenticate(headspaceHubRoutes.LOGIN_REDIRECT));
      // Clean up any token if request deauthenticate fails
      removeCookie(AUTH_TOKEN_COOKIE_NAME);
      window.location.replace(headspaceHubRoutes.LOGIN_REDIRECT);
      return;
    }

    const requiredHubAccessGroups = [
      ACCESS_GROUPS.EAP_PRIVILEGE,
      ACCESS_GROUPS.INTL_THERAPY,
    ];
    const hasRequiredPrivileges = requiredHubAccessGroups.some((group) =>
      privileges.includes(group),
    );

    if (!hasRequiredPrivileges) {
      yield put({
        type:
          HeadspaceHubMemberDetailsActionTypes.CLINICAL_COVERED_USER_LOG_IN_FAILED,
      });
      router.navigate(
        `${headspaceHubRoutes.BENEFITS_PATH}/${headspaceHubRoutes.NO_ACCESS_ERROR_PATH}`,
      );
      return;
    }

    yield put({
      type:
        HeadspaceHubMemberDetailsActionTypes.CLINICAL_COVERED_USER_IS_LOGGED_IN,
    });
    return;
  }

  if (sequence === 'done') {
    if (!error) {
      yield put({ type: MemberEnrollActionTypes.USER_IS_LOGGED_IN });
    } else {
      yield put({ type: MemberEnrollActionTypes.FAILED_USER_IS_LOG_IN });
      logSentryRequestError(error, 'waitforAuthSaga: FAILED_USER_IS_LOG_IN');
    }
  }
}
