import * as R from 'ramda';
import { RootState } from '../../../../state/types';
import { ACCESS_GROUPS } from '../../constants/accessGroups';

export const selectHSUserJWT = (state: RootState) =>
  R.pathOr(
    null,
    ['authentication', 'tokens', 'accessToken', 'data', 'token'],
    state,
  );

export const selectHsUserId = (state: RootState): string =>
  R.pathOr(null, ['authentication', 'user', 'profile', 'data', 'id'], state);

export const selectPrivileges = (state: RootState): ACCESS_GROUPS[] => {
  return state.authentication?.user?.profile?.data?.privileges ?? [];
};

export const selectFetchingUserProfileStatus = (state: RootState): boolean =>
  R.pathOr(false, ['eapAuthentication', 'fetchedUserStatus'], state);

export const selectWebAuthEmail = (state: RootState) =>
  R.pathOr('', ['authentication', 'user', 'profile', 'data', 'email'], state);

export const selectWebAuthFirstName = (state: RootState): string =>
  R.pathOr(
    '',
    ['authentication', 'user', 'profile', 'data', 'firstName'],
    state,
  );

export const getHasHubAccess = (state: RootState): boolean => {
  const requiredHubAccessGroups = [
    ACCESS_GROUPS.EAP_PRIVILEGE,
    ACCESS_GROUPS.INTL_THERAPY,
  ];
  const privileges = selectPrivileges(state);
  const hasHsHubAccess = requiredHubAccessGroups.some((accessGroup) =>
    privileges.includes(accessGroup),
  );
  return hasHsHubAccess;
};
