import { css } from '@emotion/react';
import {
  PrimaryButton,
  useActiveBreakpoint,
  Breakpoint,
  TextLink,
  Size,
  legalTextCss,
  TertiaryButton,
} from '@headspace/web-ui-components';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import ClockIcon from '../../../../../../assets/umd-recommended-clock-icon.svg';
import HeadspaceIcon from '../../../../../../assets/umd-recommended-headspace.svg';
import ProgramIcon from '../../../../../../assets/umd-recommended-program-icon.svg';
import { QRCode } from '../../../../../../shared-components/qrcode';
import { PageWrapper } from '../onboarding-shared-components/OnboardingPageWrapper';
import {
  ContainerStyle,
  centerElementStyle,
  stackedColumns,
  ListRowStyle,
  downloadHeadspaceMobile,
  downloadHeadspace,
  programCardStyle,
  programIconStyle,
  headspaceIconContainerStyle,
  headspaceIconStyle,
  meetWithCoachStyle,
  ListRowItemWithGapStyle,
  onlyOnMobile,
  textBottomMargin,
  textTopMargin,
  meetWithCoachTextBodyStyle,
  moreForYouStyle,
  hsHubContainerStyle,
  hsHubIconStyle,
  hsHubTitleStyle,
  hsHubTextStyle,
  hsHubButtonStyle,
} from './styles';
import { getUnifiedEligibilityRoutes } from '../../../../constants/routes';
import {
  onboardingButtonClickthrough,
  onboardingRecommendHeadspaceViewAction,
} from '../../../../../../analytics/events/onboardingEvents';
import { useGetAppStoreLinks } from '../../../../../../hooks/useAppStoreLinks';
import { Product } from '../../../../constants';
import {
  getEligibleCoaching,
  getTopGoalAnswer,
} from '../../../../state/slice/onboarding/onboardingSelector';
import { getSlug } from '../../../../state/selectors/orgSectionSelector/orgSectionSelector';
import { getEmail } from '../../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { checkIfMobileOrTablet, checkIfTablet } from '../../utils/deviceUtils';
import folderIcon from '../../../../../../assets/umd-skip-onboarding-module-nextSteps.svg';
import { headspaceHubRoutes } from '../../../../../headspaceHub/constants/routes';
import { hasClinicalCoverage } from '../../../../../../state/selectors/organizationSelectors';
import { useFeatureFlag } from '../../../../../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../../../../../constants/featureFlags';

export const RecommendHeadspace = () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const activeBreakpoint = useActiveBreakpoint();
  const isMobileView = useMemo(() => activeBreakpoint === Breakpoint.MOBILE, [
    activeBreakpoint,
  ]);
  const email = useSelector(getEmail);

  const [isHubAccessForIntlEnable] = useFeatureFlag(
    FEATURE_FLAG.HUB_ACCESS_FOR_INTL_CARE_PACKAGE,
  );
  const isClinicalCovered = useSelector(hasClinicalCoverage);
  const hasHubAccess = isHubAccessForIntlEnable && isClinicalCovered;

  const { appStoreLink, trackableAppStoreLink } = useGetAppStoreLinks({
    app: Product.HEADSPACE,
  });

  const slug = useSelector(getSlug);
  const { SCHEDULING_PATH } = getUnifiedEligibilityRoutes(slug);
  const navigate = useNavigate();
  const [topGoal] = useSelector(getTopGoalAnswer);
  const isEligibileForCoaching = useSelector(getEligibleCoaching);
  const isMobileOrTablet = checkIfMobileOrTablet();

  useEffect(() => {
    dispatch(onboardingRecommendHeadspaceViewAction());
  }, [dispatch]);

  const handleRecommendButtonClick = useCallback(
    (event) => {
      event.stopPropagation();
      const buttonText = event.target.innerText;
      dispatch(onboardingButtonClickthrough(buttonText));
    },
    [dispatch],
  );

  const handleMeetCoachLinkClick = useCallback(
    (event) => {
      event.preventDefault();
      const buttonText = event.target.innerText;
      dispatch(onboardingButtonClickthrough(buttonText));
      navigate(SCHEDULING_PATH);
    },
    [SCHEDULING_PATH, dispatch, navigate],
  );

  const handleHubLinkClick = useCallback(() => {
    navigate(headspaceHubRoutes.BENEFITS_PATH);
  }, []);

  const isTablet = checkIfTablet();

  return (
    <PageWrapper
      progress={100}
      header={translate('onboarding.recommend.headspace.header')}
      subHeader={translate('onboarding.recommend.headspace.subHeader', {
        topGoal,
      })}
      hideTitleSection={isMobileView}
    >
      <div
        css={ContainerStyle}
        data-testid="recommend-headspace-page-container"
      >
        <h1 css={css(centerElementStyle, onlyOnMobile)}>
          {translate('onboarding.recommend.headspace.header')}
        </h1>
        <p css={css(centerElementStyle, onlyOnMobile)}>
          {translate('onboarding.recommend.headspace.subHeader', {
            topGoal,
          })}
        </p>

        <div css={css(ListRowStyle, programCardStyle)}>
          <h2
            css={css(textBottomMargin, {
              marginTop: 0,
            })}
          >
            {translate(
              'onboarding.recommend.headspace.selfGuidedSection.header',
            )}
          </h2>

          <div css={ListRowItemWithGapStyle}>
            <div>
              <p css={textTopMargin}>
                {translate(
                  'onboarding.recommend.headspace.selfGuidedSection.subHeader',
                )}
              </p>
              <div>
                <img
                  css={programIconStyle}
                  src={ProgramIcon}
                  alt=""
                  role="presentation"
                />
                {translate(
                  'onboarding.recommend.headspace.selfGuidedSection.type',
                )}
              </div>
              <div>
                <img
                  css={programIconStyle}
                  src={ClockIcon}
                  alt=""
                  role="presentation"
                />
                {translate(
                  'onboarding.recommend.headspace.selfGuidedSection.length',
                )}
              </div>
            </div>
            <div css={headspaceIconContainerStyle}>
              <img
                css={headspaceIconStyle}
                src={HeadspaceIcon}
                alt=""
                role="presentation"
              />
            </div>
          </div>
        </div>

        {isMobileOrTablet ? (
          <div css={downloadHeadspaceMobile}>
            <a
              css={css({
                margin: 'auto',
              })}
              href={appStoreLink}
              target="_blank"
              rel="noreferrer"
            >
              <PrimaryButton onClick={handleRecommendButtonClick}>
                {translate('onboarding.recommend.headspace.button')}
              </PrimaryButton>
            </a>

            <p>
              {translate('onboarding.recommend.headspace.buttonInfo', {
                email,
              })}
            </p>
          </div>
        ) : (
          <div css={downloadHeadspace} data-testid="download-headspace-qrcode">
            <div css={stackedColumns}>
              <h2
                css={css({
                  margin: '0.25rem',
                })}
              >
                {translate('onboarding.recommend.headspace.qrCodeHeader')}
              </h2>
              <p css={[textTopMargin, legalTextCss]}>
                {translate('onboarding.recommend.headspace.buttonInfo', {
                  email,
                })}
              </p>
            </div>
            <QRCode
              data={trackableAppStoreLink}
              id="download-headspace-app"
              filename="download-headspace-app"
              margin={3}
              scale={3}
            />

            {isTablet ? (
              <TextLink
                to={appStoreLink}
                css={css(legalTextCss, {
                  margin: 'auto',
                })}
              >
                {translate('onboarding.recommend.headspace.button')}
              </TextLink>
            ) : null}
          </div>
        )}
        {isEligibileForCoaching && (
          <div css={meetWithCoachStyle}>
            <h2 css={textBottomMargin}>
              {translate('onboarding.recommend.headspace.meetWithCoach.header')}
            </h2>
            <p css={meetWithCoachTextBodyStyle}>
              {translate(
                'onboarding.recommend.headspace.meetWithCoach.subHeader',
              )}
            </p>

            <TextLink to="" onClick={handleMeetCoachLinkClick} size={Size.M}>
              {translate('onboarding.recommend.headspace.meetWithCoach.link')}
            </TextLink>
            <p css={legalTextCss}>
              {translate(
                'onboarding.recommend.headspace.meetWithCoach.linkInfo_v2',
              )}
            </p>
          </div>
        )}

        {hasHubAccess && (
          <div css={moreForYouStyle} data-testid="headspace-hub">
            <h2>
              {translate('onboarding.recommend.headspace.moreForYou.header')}
            </h2>
            <div css={hsHubContainerStyle}>
              <div>
                <h2 css={hsHubTitleStyle}>
                  {translate(
                    'onboarding.recommend.headspace.moreForYou.subHeader',
                  )}
                </h2>
                <p css={hsHubTextStyle}>
                  {translate(
                    'onboarding.recommend.headspace.moreForYou.message',
                  )}
                </p>
              </div>
              <img
                src={folderIcon}
                alt="folder-icon"
                role="presentation"
                css={hsHubIconStyle}
              />
            </div>
            <TertiaryButton
              onClick={handleHubLinkClick}
              dataTestIdPrefix="hub-link"
              css={hsHubButtonStyle}
            >
              {translate(
                'enrollment.skipOnboarding.nextStepsPage.headspaceHub.section.buttonText',
              )}
            </TertiaryButton>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};
