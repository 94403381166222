import { useGate } from 'statsig-react';

// https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/use_eligibility_service_coe
// Flag to use Eligibility Service with the Change of Enrollment flow
export const KEY = 'use_eligibility_service_coe';

export const useEligibilityServiceCoE = (): [boolean, boolean] => {
  const { value, isLoading } = useGate(KEY);

  return [value, isLoading];
};
