import { ThunkAction } from '@reduxjs/toolkit';

import type { RootState } from '../../types';
import type { HydrateAction } from '../../types/organization';
import { updateOrganizationEnrollment } from '../../../rest';
import { convertDateToUtc } from '../../../utils/dates';
import { parseError } from '../../../utils/parseError';
import {
  getOrgCustomWorkIdFieldPlaceholderText,
  useChangeOfEnrollmentWithHierarchy,
} from '../../selectors/organizationSelectors';
import { ModalActionsTypes } from '../../types/modal';
import { hydrate } from './hydrate';
import {
  OrganizationActionsTypes,
  SaveOrganizationEnrollmentAction,
} from '../../types/organization';

export const saveOrganizationEnrollment = (): ThunkAction<
  Promise<void>,
  RootState,
  void,
  | SaveOrganizationEnrollmentAction
  | { type: typeof ModalActionsTypes.OPEN_MODAL }
  | HydrateAction
> => {
  return async (dispatch, getState) => {
    const state = getState();
    const canChangeEnrollment = useChangeOfEnrollmentWithHierarchy(state);
    const {
      manageOrganization: {
        cachedOrg,
        campaignDisplayName,
        campaignLaunchDate,
        orgId,
        slug,
        restrictBy,
        headline,
        body,
        learn_more_url_path,
        overflow_url,
        restrictedDomains,
        addOns,
        dependentEnrollmentFlowDisplayName,
      },
      restrictByMigration: {
        elFileReport: { id: changeOfEnrollmentElfId } = { elfId: null },
      } = {},
    } = state;
    const campaignLaunchDateUTC = campaignLaunchDate
      ? convertDateToUtc(campaignLaunchDate).toISOString()
      : null;
    const workIdFieldPlaceholderText = getOrgCustomWorkIdFieldPlaceholderText(
      state,
    );

    try {
      const editOrgEnrollment = await updateOrganizationEnrollment(orgId, {
        addOns,
        body: `${body}`,
        campaignDisplayName: campaignDisplayName || null,
        campaignLaunchDate: campaignLaunchDateUTC,
        changeOfEnrollmentElfId,
        dependentEnrollmentFlowDisplayName:
          dependentEnrollmentFlowDisplayName || null,
        headline: `${headline}`,
        learnMoreUrl: learn_more_url_path || null,
        overflowUrl: overflow_url,
        restrictBy,
        restrictedDomains,
        slug,
        useChangeOfEnrollmentWithHierarchy: canChangeEnrollment,
        workIdFieldPlaceholderText: workIdFieldPlaceholderText || null,
      });

      dispatch({
        payload: {
          addOns: editOrgEnrollment.data.addOns,
          body: editOrgEnrollment.data.body,
          cachedOrg: {
            ...cachedOrg,
            enrollment: {
              addOns,
              body,
              campaignDisplayName,
              campaignLaunchDate,
              headline,
              learnMoreUrl: learn_more_url_path,
              overflowUrl: overflow_url,
              restrictBy,
              restrictedDomains,
              slug,
            },
          },
          campaignDisplayName: editOrgEnrollment.data.campaignDisplayName,
          campaignLaunchDate,
          dependentEnrollmentFlowDisplayName:
            editOrgEnrollment.data.dependentEnrollmentFlowDisplayName,
          headline: editOrgEnrollment.data.headline,
          learn_more_url_path: editOrgEnrollment.data.learnMoreUrl,
          overflow_url: editOrgEnrollment.data.overflowUrl,
          restrictBy: editOrgEnrollment.data.restrictBy,
          restrictedDomains: editOrgEnrollment.data.restrictedDomains,
          slug: editOrgEnrollment.data.slug,
          workIdFieldPlaceholderText:
            editOrgEnrollment.data.workIdFieldPlaceholderText,
        },
        type: OrganizationActionsTypes.SAVE_ORGANIZATION_ENROLLMENT,
      });
    } catch (err: any) {
      dispatch({
        payload: {
          message: parseError(err).message,
        },
        type: ModalActionsTypes.OPEN_MODAL,
      });

      if (cachedOrg) dispatch(hydrate(cachedOrg));
    }
  };
};
